import React, { useEffect, useState } from 'react';
import API, { baseURL } from '../api/api';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Avatar,
  Grid,
  Box,
  Divider,
} from '@mui/material';

const AllChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await API.get('/club/challenges/');
        // Filter only active challenges
        const activeChallenges = response.data.filter(challenge => challenge.status === "AC");
        setChallenges(activeChallenges);
      } catch (error) {
        console.error('Klaida ruošiant skaitymo iššūkius:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={2}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 2, mb: 2, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box flexGrow={1}>
        <Typography variant="h4" gutterBottom>
          Visi skaitymo iššūkiai
        </Typography>
        {challenges.length > 0 ? (
          <List>
            {challenges.map((challenge) => (
              <ListItem
                button
                component={Link}
                to={`/club/challenges/${challenge.id}/`}
                key={challenge.id}
                sx={{
                  backgroundColor: 'secondary.main',
                  marginBottom: '1rem',
                  padding: '1rem',
                  borderRadius: '8px',
                  color: 'text.primary',
                }}
              >
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                      {challenge.books.map((book) => {
                        const coverURL = book.cover
                          ? book.cover.startsWith('http')
                            ? book.cover
                            : `${baseURL}${book.cover}`
                          : '/default_cover.jpg';

                        return (
                          <Avatar
                            key={book.id}
                            src={coverURL}
                            alt={book.title}
                            sx={{ width: 100, height: 150, marginRight: '8px' }}
                            variant="rounded"
                          />
                        );
                      })}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <ListItemText
                      primary={
                        <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                          {challenge.name}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ color: 'text.primary' }}>Kol kas nėra jokių skaitymo iššūkių</Typography>
        )}
      </Box>

      {/* Footer with Mission Statement */}
      <Box component="footer" sx={{ mt: 'auto', textAlign: 'center', py: 1 }}>
        <Divider />
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          Skaitome kartu, augame kartu
        </Typography>
      </Box>
    </Container>
  );
};

export default AllChallenges;
