// src/components/FrontPage.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import API, { baseURL } from '../api/api'; // Ensure baseURL is correctly defined in your API module
import {
  Box,
  Button,
  Typography,
  Grid,
  Avatar,
  Container,
  CircularProgress,
  Divider,
} from '@mui/material';
import Slider from 'react-slick'; // Import React Slick
import Aptarkit from '../assets/aptarkit.png';
import Issirinkit from '../assets/issirinkit.png';
import Skaitykit from '../assets/skaitykit.png';

// Import Slick Carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FrontPage = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await API.get('/club/challenges/');
        const activeChallenges = response.data.filter(challenge => challenge.status === "AC");
        setChallenges(activeChallenges);
      } catch (error) {
        console.error('Error fetching challenges:', error);
        setError('Failed to load challenges. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []);

  // Carousel settings for React Slick
  const sliderSettings = {
    dots: true, // Show navigation dots
    infinite: challenges.length > 3, // Infinite loop only if more than 3 challenges
    speed: 500,
    slidesToShow: challenges.length >= 3 ? 3 : challenges.length, // Show up to 3 slides at a time
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 960, // Medium screens
        settings: {
          slidesToShow: challenges.length >= 2 ? 2 : challenges.length,
        },
      },
      {
        breakpoint: 600, // Small screens
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    arrows: true, // Show next and previous arrows
    adaptiveHeight: true, // Adjust height based on content
  };

  // Function to render each challenge with its books
  const renderChallenge = (challenge) => (
    <Box
      key={challenge.id}
      sx={{
        padding: 2,
        boxSizing: 'border-box',
        minWidth: 220, // Adjusted minimum width for each slide
        margin: '0 10px',
        // Removed backgroundColor and boxShadow for a transparent background
      }}
    >
      {/* Challenge Name */}
      <Typography variant="h6" gutterBottom>
        {challenge.name}
      </Typography>

      {/* Books Grid */}
      <Grid container spacing={1} justifyContent="center">
        {challenge.books.map((book) => (
          <Grid item key={book.id}>
            <Avatar
              src={
                book.cover.startsWith('http')
                  ? book.cover
                  : `${baseURL}${book.cover}`
              }
              variant="rounded"
              sx={{ width: 80, height: 120 }} // Adjusted size to fit more tightly
              alt={book.title}
              imgProps={{
                onError: (e) => {
                  e.target.onerror = null; // Prevents infinite loop if fallback also fails
                  e.target.src = '/media/default_cover.jpg'; // Path to your fallback image
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <Container maxWidth="md">
      <Box my={4} textAlign="center">
        {/* Section 1: App Usage Pictures */}
        <Grid container spacing={4} direction="column" alignItems="center">
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={Issirinkit}
              alt="Išsirink"
              style={{ width: '100%', height: 'auto', borderRadius: 12 }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={Skaitykit}
              alt="Skaityk"
              style={{ width: '100%', height: 'auto', borderRadius: 12 }}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <img
              src={Aptarkit}
              alt="Aptark"
              style={{ width: '100%', height: 'auto', borderRadius: 12 }}
            />
          </Grid>
        </Grid>

        {/* Section 2: Challenges Carousel */}
        <Box mt={6}>
          <Typography variant="h4" gutterBottom>
            Iššūkiai
          </Typography>

          {loading ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          ) : challenges.length > 0 ? (
            <Box mt={4}>
              <Slider {...sliderSettings}>
                {challenges.map(renderChallenge)}
              </Slider>
            </Box>
          ) : (
            <Typography variant="body1">
              No challenges available at the moment.
            </Typography>
          )}
        </Box>

        <Box mt={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/login')}
            size="large"
          >
            Prisijungti
          </Button>
        </Box>


        {/* Footer with Mission Statement */}
        <Box component="footer" sx={{ marginTop: '4rem', textAlign: 'center', py: 3 }}>
          <Divider />
          <Typography variant="subtitle1" sx={{ mt: 2 }}>
            Skaitome kartu, augame kartu
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default FrontPage;
